import Swal from "sweetalert2/dist/sweetalert2.js";
import { lang } from "../lang";

let alert = function(data) {
    if (data == null) data = null;

    if (data.type == null) data.type = "error";

    if (data.type == "success") {
        if (data.title == null)
            data.title = lang.get("general.alert-success-title");

        if (data.message == null)
            data.message = lang.get("general.alert-success-message");
    } else {
        if (data.title == null)
            data.title = lang.get("general.alert-error-title");

        if (data.message == null)
            data.message = lang.get("general.alert-error-message");
    }

    if (data.buttonText == null) data.buttonText = lang.get("general.okay");

    var options = {
        icon: data.type,
        title: data.title,
        html: data.message,
        confirmButtonText: data.buttonText
    };

    if (data.onAfterClose) {
        options.onAfterClose = data.onAfterClose;
    }

    if (data.escape == false) {
        options.allowOutsideClick = false;
        options.showCancelButton = false;
        options.showCloseButton = false;
        options.allowEscapeKey = false;
        options.showConfirmButton = false;
    }

    if (data.onlyConfirm == true) {
        options.allowOutsideClick = false;
        options.showCancelButton = false;
        options.showCloseButton = false;
        options.allowEscapeKey = false;
        options.showConfirmButton = true;
    }

    Swal.fire(options);
};

export default alert;
