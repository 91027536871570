let source = {
    "en.general": require("../../resources/lang/en/general.php"),
    "tr.general": require("../../resources/lang/tr/general.php"),
    "en.proposal": require("../../resources/lang/en/proposal.php"),
    "tr.proposal": require("../../resources/lang/tr/proposal.php")
};

import Lang from "lang.js";

var lang = new Lang({
    messages: source,
    fallback: "tr"
});

export { lang };
