require("./bootstrap");

// Plugins
require("@fancyapps/fancybox/dist/jquery.fancybox");
require("./plugins/jquery.wizard");
require("./plugins/jquery.blockUI");
require("card");

// Theme
require("./theme/index");

require("./lang");

window.moment = require('moment');

import Valde from "./valde/index";
window.Valde = Valde;

$.ajaxSetup({
    headers: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
    }
});
