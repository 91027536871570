var Valde = {
    init: function () {
        (function ($) {
            "use strict";
            // Detecting IEF
            var oldIE;
            if ($("html").is(".lt-ie7, .lt-ie8, .lt-ie9")) {
                oldIE = true;
            }
            if (oldIE) {
                window.location.href = "https://outdatedbrowser.com/tr";
            }
        })(jQuery);

        for (var prop in Valde) {
            if (typeof Valde[prop].init == "function") {
                Valde[prop].init();
            }
        }
    }
};

import { form } from "./form";
Valde.form = form;

import { steps } from "./steps";
Valde.steps = steps;

import { block, unblock } from "./block";
Valde.block = block;
Valde.unblock = unblock;

import alert from "./alert";
Valde.alert = alert;

import { make } from "./make";
Valde.make = make;

import { parsley } from "./parsley";
Valde.parsley = parsley;

import { lang } from "./lang";
Valde.lang = lang;

import { helper } from "./helper";
Valde.helper = helper;

import { mask } from "./mask";
Valde.mask = mask;

import { select } from "./select";
Valde.select = select;

import { modal } from "./modal";
Valde.modal = modal;

import { cookie } from "./cookie";
Valde.cookie = cookie;

export default Valde;
