let form = {
    init: function () {
        $("body").on("click", ".input-group .input-group-append", function () {
            var $group = $(this).parents(".input-group");
            $group.find("input").focus();
        });

        $('body').on('change', 'input[type=radio][data-change="toggle"]', function () {

            var $form = $(this).parents('form');
            var $group = $('[data-toggle-group-id="' + $(this).data('toggle-group') + '"]');
            var target = $(this).data('toggle-target');

            Valde.form.toggle($form, $group, target);

        });
    },

    toggle: function ($form, $group, target) {

        $group.each(function () {

            var ids = $(this).data('toggle-target-id').split(",");

            if ($(this).hasClass('hide-target')) {

                if (ids.includes(target)) {
                    var action = 'hide';
                } else {
                    var action = 'show';
                }

            } else {

                if (ids.includes(target)) {
                    var action = 'show';
                } else {
                    var action = 'hide';
                }

            }

            if (action == 'hide') {
                $(this).find('input,select,textarea').each(function () {
                    $(this).prop("disabled", true);
                });
                $(this).removeClass('show');
            } else {
                $(this).find('input,select,textarea').each(function () {
                    $(this).prop("disabled", false);
                });
                $(this).addClass('show');
            }

        });

    },
};

export { form };
