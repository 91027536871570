import $ from 'jquery';
import 'parsleyjs';

$.extend(window.Parsley.options, {
    errorsWrapper: '<div class="parsley-errors-list"></div>',
    errorTemplate: '<div class="alert alert-danger"></div>',
    excluded: 'input[type=button], input[type=submit], input[type=reset], [disabled]',
    inputs: 'input, textarea, select, input[type=hidden], :hidden',
    errorsContainer: function (el) {
        if (el.$element.parent().hasClass('input-group')) {
            return el.$element.parent().parent();
        } else {
            if (el.$element.attr('type') == 'radio') {
                return el.$element.parents('.form-group');
            } else {
                return el.$element.parent();
            }
        }
    },
});

window.Parsley.on('form:error', function (form) {

    var $error = form.$element.find('.parsley-error:first').parents('.form-group');

    var offsetPosition = $error.offset().top - 32;

    window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
    });

});

let parsley = {

    init: function () {



    },

};

export { parsley };