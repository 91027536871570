let steps = {
    init: function() {
        $("body").on("click", "[data-steps]", function(e) {
            e.preventDefault();

            if ($(this).data("disabled") == true) {
                return false;
            }

            var $wizard = $($(this).data("steps"));
            var step = $(this).data("step");

            if (step == "next") {
                step = $wizard.data("step") + 1;
            }

            var prop = $wizard.data("steps-prop");
            var func = $wizard.data("steps-function");

            Valde[prop][func]($wizard, step);
        });
    }
};

export { steps };
