let modal = {

    init: function () {

        $("body").on("click", "a[data-modal=true]", function (e) {

            e.preventDefault();

            Valde.block('body');

            var button = $(this);
            var $div = $("#modal");
            var url = button.attr('href');
            var method = 'get';

            $.ajax({
                url: url,
                type: method,
                cache: false,
                success: function (result) {
                    $.unblockUI();
                    $div.html(result);
                    $div.modal();
                },
                error: function (xhr, ajaxOptions, thrownError) {

                    $.unblockUI();

                    Valde.proposal.disabled = false;

                    if (xhr.responseJSON.message) {
                        Valde.alert({
                            message: xhr.responseJSON.message
                        });
                    } else {
                        Valde.alert();
                    }

                }

            });
        });

    },

}

export { modal };
