let block = function(target, options) {
    var el = $(target);

    options = $.extend(
        true,
        {
            opacity: 0.5,
            overlayColor: "#ffffff"
        },
        options
    );

    var html = '<div class="block-icon">';
    html += '<i class="fas fa-spinner fa-spin"></i>';

    if (options.message) {
        html += '<div class="block-message">' + options.message + "</div>";
    }

    html += "</div>";

    var params = {
        message: html,
        centerY: true,
        centerX: true,
        css: {
            top: "50%",
            left: "50%",
            border: "0",
            padding: "0",
            backgroundColor: "none"
        },
        overlayCSS: {
            backgroundColor: options.overlayColor,
            opacity: options.opacity,
            cursor: "wait"
        },
        onUnblock: function() {
            if (el) {
                el.css("position", "");
                el.css("zoom", "");
            }
        }
    };

    if (target == "body") {
        params.baseZ = 9999;
        $.blockUI(params);
    } else {
        var el = $(target);
        el.block(params);
    }
};

let unblock = function(target) {
    if (target && target != "body") {
        $(target).unblock();
    } else {
        $.unblockUI();
    }
};

export { block, unblock };
