import Inputmask from "inputmask";

let mask = {

    date: new Inputmask("99/99/9999"),

    init: function () {
        this.bind($('body'));
    },

    bind: function ($container) {

        $container.find('.mask-date').each(function () {
            mask.date.mask($(this));
        });

    },

};

export { mask };
