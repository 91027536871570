/*! jQuery Wizard v0.1 | (c) Valde | valde.co */
(function ($) {
    $.wizard = function (element, options) {
        var defaults = {
            start: 1
        };

        var plugin = this;

        plugin.settings = {};

        var $element = $(element),
            element = element;

        plugin.init = function () {
            plugin.settings = $.extend({}, defaults, options);

            $element.data("step", plugin.settings.start);

            $element
                .find(
                    '.step-content[data-step="' + plugin.settings.start + '"]'
                )
                .addClass('show');

            $element
                .find(".step-content-cumulative")
                .filter(function () {
                    return (
                        parseInt($(this).attr("data-step")) <=
                        plugin.settings.start
                    );
                })
                .addClass('show');

            $('a[data-steps="#' + $element.attr("id") + '"]')
                .filter(function () {
                    return (
                        parseInt($(this).attr("data-step")) <
                        plugin.settings.start
                    );
                })
                .addClass("success");

            $(
                'a[data-steps="#' +
                $element.attr("id") +
                '"][data-step="' +
                plugin.settings.start +
                '"]'
            ).addClass("active");
        };

        plugin.step = function () {
            return $element.data("step");
        };

        plugin.go = function (target, callback) {
            var current = plugin.step();

            $element
                .find(".step-content-cumulative")
                .filter(function () {
                    return parseInt($(this).attr("data-step")) > target;
                })
                .removeClass('show');

            $element
                .find(".step-content[data-step='" + current + "']")
                .removeClass('show');

            $element
                .find(".step-content-cumulative")
                .filter(function () {
                    return (
                        parseInt($(this).attr("data-step")) <= target
                    );
                })
                .addClass('show');

            $element
                .find(".step-content[data-step='" + target + "']")
                .addClass('show');

            $element.data("step", target);

            $(
                'a[data-steps="#' + $element.attr("id") + '"]'
            ).removeClass("active");
            $(
                'a[data-steps="#' +
                $element.attr("id") +
                '"][data-step="' +
                target +
                '"]'
            )
                .removeClass("success")
                .addClass("active");

            if (current > target) {
                $(
                    'a[data-steps="#' +
                    $element.attr("id") +
                    '"]'
                )
                    .filter(function () {
                        return (
                            parseInt(
                                $(this).attr("data-step")
                            ) > target
                        );
                    })
                    .removeClass("success");
            } else {
                $(
                    'a[data-steps="#' +
                    $element.attr("id") +
                    '"]'
                )
                    .filter(function () {
                        return (
                            parseInt(
                                $(this).attr("data-step")
                            ) < target
                        );
                    })
                    .addClass("success");
            }

            $("html, body")
                .stop(true, false)
                .animate(
                    {
                        scrollTop: 0
                    },
                    1000
                );

            $element
                .find("section.step-content")
                .promise()
                .done(function () {
                    if (typeof callback === "function") {
                        callback();
                    }
                });
        };

        plugin.init();
    };

    $.fn.wizard = function (options) {
        return this.each(function () {
            if (undefined == $(this).data("wizard")) {
                var plugin = new $.wizard(this, options);
                $(this).data("wizard", plugin);
            }
        });
    };
})(jQuery);
