import { lang } from "../lang";

let make = {
    init: function () {

        $('body').on('change', '[data-make-change="usage-style"]', function (e) {

            var $group = $(this).parents('.group-make');

            var $brand = $group.find('[data-make-change="brand"]');
            var $year = $group.find('[data-make-change="model-year"]');
            var $model = $group.find('[data-make-change="model"]');

            var url = $brand.data('url');

            var val = $(this).val();

            if (val > 0) {

                $year.prop("disabled", true);

                $brand.html('<option value="">' + lang.get("general.brand-select") + '</option>').prop("disabled", true);
                $model.html('<option value="">' + lang.get("general.model-select") + '</option>').prop("disabled", true);

                $.ajax({
                    url: url,
                    type: 'get',
                    data: {
                        usageStyle: val,
                        year: $year.val()
                    },
                    success: function (result) {

                        $.each(result.data, function (i, d) {
                            $brand.append('<option value="' + d.brand_id + '">' + d.brand_name + '</option>');
                        });

                        $year.prop("disabled", false);
                        $brand.prop("disabled", false);
                        $model.prop("disabled", false);

                    }

                });

            } else {
                $brand.prop("disabled", false);
                $year.prop("disabled", false);
                $model.prop("disabled", false);
            }

        });


        $('body').on('change', '[data-make-change="model-year"]', function (e) {

            var $group = $(this).parents('.group-make');

            var $usageStyle = $group.find('[data-make-change="usage-style"]');
            var $brand = $group.find('[data-make-change="brand"]');
            var $model = $group.find('[data-make-change="model"]');

            var url = $brand.data('url');

            var val = $(this).val();
            var usageStyleVal = $usageStyle.val();

            if (val > 0 && usageStyleVal > 0) {

                $brand.html('<option value="">' + lang.get("general.brand-select") + '</option>').prop("disabled", true);
                $model.html('<option value="">' + lang.get("general.model-select") + '</option>').prop("disabled", true);

                $.ajax({
                    url: url,
                    type: 'get',
                    data: {
                        usageStyle: usageStyleVal,
                        year: val
                    },
                    success: function (result) {

                        $brand.prop("disabled", false);
                        $model.prop("disabled", false);

                        $.each(result.data, function (i, d) {
                            $brand.append('<option value="' + d.brand_id + '">' + d.brand_name + '</option>');
                        });

                    }

                });

            } else {
                $brand.prop("disabled", false);
                $model.prop("disabled", false);
            }

        });

        $('body').on('change', '[data-make-change="brand"]', function (e) {

            var $group = $(this).parents('.group-make');

            var $usageStyle = $group.find('[data-make-change="usage-style"]');
            var $year = $group.find('[data-make-change="model-year"]');
            var $model = $group.find('[data-make-change="model"]');

            var url = $model.data('url');

            var val = $(this).val();
            var usageStyleVal = $usageStyle.val();
            var yearVal = $year.val();

            if (val > 0 && usageStyleVal > 0 && yearVal > 0) {

                $model.html('<option value="">' + lang.get("general.model-select") + '</option>').prop("disabled", true);

                $.ajax({
                    url: url,
                    type: 'get',
                    data: {
                        brandId: val,
                        usageStyle: usageStyleVal,
                        year: yearVal
                    },
                    success: function (result) {

                        $model.prop("disabled", false);

                        $.each(result.data, function (i, d) {
                            $model.append('<option value="' + d.model_id + '">' + d.model_name + '</option>');
                        });

                    }

                });

            } else {
                $model.prop("disabled", false);
            }

        });


    }
};

export { make };
