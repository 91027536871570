let helper = {
    init: function() {
        $("body").on("input propertychange", ".helper-to-upper", function() {
            $(this).val(Valde.helper.toUpper($(this).val()));
        });
    },

    toUpper: function(string) {
        var letters = {
            i: "İ",
            ş: "Ş",
            ğ: "Ğ",
            ü: "Ü",
            ö: "Ö",
            ç: "Ç",
            ı: "I"
        };
        string = string.replace(/(([iışğüçö]))/g, function(letter) {
            return letters[letter];
        });
        return string.toUpperCase();
    },

    formatMoney: function(num) {
        num = num.toString().replace(".", ",");
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    },

    validateTckn: function(value) {
        var isEleven = /^[0-9]{11}$/.test(value);
        var totalX = 0;
        for (var i = 0; i < 10; i++) {
            totalX += Number(value.substr(i, 1));
        }
        var isRuleX = totalX % 10 == value.substr(10, 1);
        var totalY1 = 0;
        var totalY2 = 0;
        for (var i = 0; i < 10; i += 2) {
            totalY1 += Number(value.substr(i, 1));
        }
        for (var i = 1; i < 10; i += 2) {
            totalY2 += Number(value.substr(i, 1));
        }
        var isRuleY = (totalY1 * 7 - totalY2) % 10 == value.substr(9, 0);

        if (isEleven && isRuleX && isRuleY) {
            return true;
        }

        return false;
    }
};

export { helper };
