let cookie = {
    init: function () {

        if (!localStorage.getItem('izinyolu_sigorta_cookie')) {
            $('#cookie-bar').addClass('show');
        }

        $("body").on("click", '[data-cookie]', function (e) {

            e.preventDefault();

            var action = $(this).attr('data-cookie');

            if (action == 'approve') {
                localStorage.setItem('izinyolu_sigorta_cookie', 1);
            }

            $('#cookie-bar').alert('close');

        });

    }
};

export { cookie };
