import 'select2';

import UAParser from 'ua-parser-js';

let select = {
    init: function () {

        var parser = new UAParser();

        $(".select2").select2({
            theme: 'valde'
        });
    }
};

export { select };
